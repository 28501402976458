import { Component, OnInit } from '@angular/core';
import { HomeService } from './home.service';
import { Rentability } from '../../types';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  loading = true;
  rentability: Rentability;
  landingUrl;
  onboardingUrl;

  constructor(
    private homeService: HomeService
  ) { }

  ngOnInit(): void {
    this.landingUrl = environment.landingUrl;
    this.onboardingUrl = environment.onboardingUrl;
    this.homeService.getRentability().subscribe(rentability => {
      this.rentability = rentability;
      this.loading = false;
    });
  }


}
