import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titleLowercaseAndDashes'
})
export class TitleLowercaseAndDashesPipe implements PipeTransform {

  transform(value): string {
    return value.replace(/\s+/g, '-').toLowerCase();
  }

}
