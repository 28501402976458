import { Injectable } from '@angular/core';
import { Fondo, Graph, GraphResponse } from '../../types';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FondoService {

  constructor(
    private http: HttpClient
  ) { }

  getDataChart(fondoEntity: Fondo): Observable<Graph[]> {
    const { fondo, serie } = fondoEntity;
    const url = `${environment.apiUrl}/graficorentabilidad?Fondo=${fondo}&Serie=${serie}`;
    return this.http.get(url)
      .pipe(
        map((graphResponse: GraphResponse) => graphResponse.valoresCuota)
      );
  }

}
