import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChartComponent } from './components/chart/chart.component';
import { FondoComponent } from './components/fondo/fondo.component';
import { FondosComponent } from './components/fondos/fondos.component';
import { HomeComponent } from './pages/home/home.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TitleLowercaseAndDashesPipe } from './components/fondo/title-lowercase-and-dashes.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DecimalPipe, registerLocaleData } from '@angular/common';
import localeEsAr from '@angular/common/locales/es-AR';
import { AuthInterceptor } from './auth-interceptor';
import { FooterComponent } from './components/footer/footer.component';
import { FondoPipe } from './components/fondo/fondo.pipe';
/* import { GoogleTagManagerModule } from 'angular-google-tag-manager'; */
import { environment } from 'src/environments/environment';

registerLocaleData(localeEsAr, 'es-Ar');

@NgModule({
  declarations: [
    AppComponent,
    ChartComponent,
    FondoComponent,
    FondosComponent,
    HomeComponent,
    TitleLowercaseAndDashesPipe,
    FooterComponent,
    FondoPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    /* GoogleTagManagerModule.forRoot({
      id: environment.gtmId,
    }) */
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-Ar' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    DecimalPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
