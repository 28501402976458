import { Component } from '@angular/core';
import {IntercomService} from './services/intercom.service';
import {ClarityService} from './services/clarity.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  {

  constructor(
    private intercomService: IntercomService,
    private clarityService: ClarityService,
  ) {
    this.intercomService.initWidget();
    this.clarityService.loadClarityScript();
  }

}
