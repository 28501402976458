<section class="top">
  <section class="first-column">
    <div class="header">
      <h2>{{ fondo.titulo }}</h2>
      <div
        class="percent"
        [ngClass]="{'negative': fondo.variacionAnoAnterior < 0}"
        *ngIf="fondo.variacionAnoAnterior !== null"
      >
        <i
          class="fas"
          [ngClass]="fondo.variacionAnoAnterior > 0 ? 'fa-arrow-up' : 'fa-arrow-down'"
        ></i>
        <p>
          {{ fondo.variacionAnoAnterior | number : '1.2-2' : 'es-Ar' }}%
        </p>

        <div
          class="tts:down tts-wrap tts-slideIn"
          aria-label="Variación del valor cuota de los últimos 365 días."
        >
          <span class="svg-icon-question-sm"></span>
        </div>
      </div>
    </div>
    <div class="totals">
      <div class="total">
        <p class="label">
          Último Valor
        </p>
        <p class="value">
          ${{ fondo.ultimoValor | number : '1.2-4' }}
        </p>
      </div>
      <div class="total">
        <div class="label">
          <p>
            Variación últimos 30 días
          </p>
          <div
            class="tts:down tts-wrap tts-slideIn"
            [attr.aria-label]="thirtyDaysText"
          >
            <i class="svg-icon-question-sm"></i>
          </div>
        </div>
        <p
          class="value percent"
          [ngClass]="{'negative': fondo.variacionUltimos30Dias < 0, 'reset-styles': fondo.variacionUltimos30Dias === null}"
        >
          {{ fondo.variacionUltimos30Dias | fondo }}
          <i
            class="fas"
            [ngClass]="fondo.variacionUltimos30Dias > 0 ? 'fa-arrow-up' : 'fa-arrow-down'"
            *ngIf="fondo.variacionUltimos30Dias !== null"
          ></i>
        </p>
      </div>
      <div class="total">
        <div class="label">
          <p>
            Variación últimos 90 días
          </p>
          <div
            class="tts:down tts-wrap tts-slideIn"
            [attr.aria-label]="ninetyDaysText"
          >
            <i class="svg-icon-question-sm"></i>
          </div>
        </div>
        <p
          class="value percent"
          [ngClass]="{'negative': fondo.variacionUltimos90Dias < 0, 'reset-styles': fondo.variacionUltimos90Dias === null}"
        >
          {{ fondo.variacionUltimos90Dias | fondo }}
          <i
            class="fas"
            [ngClass]="fondo.variacionUltimos90Dias > 0 ? 'fa-arrow-up' : 'fa-arrow-down'"
            *ngIf="fondo.variacionUltimos90Dias"
          ></i>
        </p>
      </div>
      <div class="total">
        <div class="label">
          <p>
            Variación YTD
          </p>
          <div
            class="tts:down tts-wrap tts-slideIn"
            aria-label="Variación del valor cuota desde el 31 de diciembre del año pasado."
          >
            <i class="svg-icon-question-sm"></i>
          </div>
        </div>
        <p
          class="value percent"
          [ngClass]="{'negative': fondo.variacionYTD < 0, 'reset-styles': fondo.variacionYTD === null}"
        >
          {{ fondo.variacionYTD | fondo}}
          <i
            class="fas"
            [ngClass]="fondo.variacionYTD > 0 ? 'fa-arrow-up' : 'fa-arrow-down'"
            *ngIf="fondo.variacionYTD"
          ></i>
        </p>
      </div>

      <div class="total">
        <div class="label">
          <p>
            Rentabilidad 2023
          </p>
          <div
            class="tts:down tts-wrap tts-slideIn"
            aria-label="Variación del valor cuota entre el 31/12/2022 y el 31/12/2023."
          >
            <i class="svg-icon-question-sm"></i>
          </div>
        </div>
        <p
          class="value percent"
          [ngClass]="{'negative': fondo.variacionUltimos3Ano < 0, 'reset-styles': fondo.variacionUltimos3Ano === null}"
        >
          {{ fondo.variacionUltimos3Ano | fondo}}
          <i
            class="fas"
            [ngClass]="fondo.variacionUltimos3Ano > 0 ? 'fa-arrow-up' : 'fa-arrow-down'"
            *ngIf="fondo.variacionUltimos3Ano"
          ></i>
        </p>
      </div>

    </div>
  </section>
  <div class="second-column">
    <button
      (click)="getDetail()"
      [disabled]="loadingDetail"
    >
      <span *ngIf="!loadingDetail">{{ textButton }}</span>
      <i
        class="fas fa-circle-notch fa-spin"
        *ngIf="loadingDetail"
      ></i>
    </button>
  </div>
</section>
<div
  class="section-chart"
  *ngIf="fondo.graph && showDetail"
  [@enter]
>
  <app-chart
    [data]="fondo.graph"
    [numberOfChart]="fondo.titulo | titleLowercaseAndDashes"
  ></app-chart>
</div>
