import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class IntercomService {
    constructor() {}

    initWidget(): void {
        if ((window as any)?.Intercom) {
            (window as any).intercomSettings = {
                api_base: 'https://api-iam.intercom.io',
                app_id: environment.intercom_app_id,
            };
        }
    }

    showBubble(): void {
        if ((window as any)?.Intercom) {
            (window as any).Intercom('show');
        }
    }

    hideBubble(): void {
        if ((window as any)?.Intercom) {
            (window as any).Intercom('update', {
                hide_default_launcher: true,
            });
            (window as any).Intercom('hide');
        }
    }

    update(config: any): void {
        if ((window as any)?.Intercom) {
            (window as any).Intercom('boot', config);
        }
    }
}
