import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ClarityService {
    private isClarityScriptLoaded = false;

    loadClarityScript(): void {
        if (this.isClarityScriptLoaded) {
            return;
        }

        if (!environment.production) {
            console.info('Dev mode: clarity script not loaded');
            return;
        }

        const scriptContent = `
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "myo4ngtmv5");
      clarity("set", "sf_app", "landing_rentabilidad");
    `;

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.text = scriptContent;
        document.head.appendChild(script);

        this.isClarityScriptLoaded = true;
    }


}
