import { Component, AfterViewInit, OnDestroy, Input } from '@angular/core';

// amCharts imports
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as es_ES from '@amcharts/amcharts4/lang/es_ES';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import * as am4plugins_rangeSelector from '@amcharts/amcharts4/plugins/rangeSelector';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements AfterViewInit, OnDestroy {
  private chart: am4charts.XYChart;

  @Input() numberOfChart = '1';
  @Input() data = [];

  constructor() { }

  ngAfterViewInit() {
    this.configureChart();
  }

  ngOnDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  private configureChart() {
    am4core.useTheme(am4themes_animated);
    am4core.options.autoSetClassName = true;
    const chart = am4core.create(this.numberOfChart, am4charts.XYChart);

    chart.numberFormatter.numberFormat = '$ #';

    chart.paddingRight = 20;
    chart.language.locale = es_ES.default;

    chart.data = this.data;

    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.tooltip.background.fill = am4core.color('#e9f0f1');
    dateAxis.tooltip.label.fill = am4core.color('#313151');
    dateAxis.tooltip.background.strokeWidth = 0;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.minWidth = 35;

    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = 'date';
    series.dataFields.valueY = 'value';
    series.tooltipText = '{valueY.value}';
    series.tooltip.autoTextColor = false;
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color('#F950A9');
    series.tooltip.label.fill = am4core.color('white');
    series.tooltip.label.fontWeight = '800';

    chart.cursor = new am4charts.XYCursor();

    const scrollbarX = new am4charts.XYChartScrollbar();
    scrollbarX.series.push(series);
    chart.scrollbarX = scrollbarX;

    // Add range selector
    var selector = new am4plugins_rangeSelector.DateAxisRangeSelector();
    selector.container = document.getElementById(`${this.numberOfChart}-controls`);
    selector.axis = dateAxis;

    chart.language.setTranslationAny('%1Y', '%1A');
    chart.language.setTranslationAny('MAX', 'TODO');

    this.chart = chart;
  }

}
