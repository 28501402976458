<footer id="page8" class="footer container-fluid position-relative pb-4 page page8">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-2">
                <img src="../../../../../../assets/logo_gray.png" class="focus-logo2" alt="Focus logo">
            </div>
            <div class="col-12 col-md-3">
                <ul class="list-unstyled text-small">
                    <h6 class="text-black mb-4">Acerca de SoyFocus</h6>
                    <li class="mb-2 mb-md-3"><a class="text-reset" href="https://soyfocus.com/como-invertimos/#Preguntas_frecuentes">Preguntas Frecuentes</a></li>
                    <li class="mb-2 mb-md-3"><a class="text-reset" href="https://soyfocus.com/como-funciona/">¿Cómo funciona?</a></li>
                    <li class="mb-2 mb-md-3"><a class="text-reset" href="https://soyfocus.com/como-invertimos/">¿Cómo invertimos?</a></li>
                    <li class="mb-2 mb-md-3"><a class="text-reset" href="https://soyfocus.com/nosotros/">Equipo</a></li>
                </ul>
            </div>
            <div class="col-12 col-md-2">
                <ul class="list-unstyled text-small">
                    <h6 class="text-black mb-4">Blog</h6>
                    <li class="mb-2 mb-md-3">
                        <a class="text-reset" href="https://soyfocus.com/categoria/consejos/">Consejos</a>
                    </li>
                    <li class="mb-2 mb-md-3">
                        <a class="text-reset" href="https://soyfocus.com/categoria/destacado/">Destacado</a>
                    </li>
                    <li class="mb-2 mb-md-3">
                        <a class="text-reset" href="https://soyfocus.com/categoria/acercade/">Acerca de</a>
                    </li>
                    <li class="mb-2 mb-md-3">
                        <a class="text-reset" href="https://soyfocus.com/categoria/prensa/">Prensa</a>
                    </li>
                </ul>
            </div>
            <div class="col-12 col-md-2">
                <ul class="list-unstyled text-small">
                    <h6 class="text-black mb-4">Legal</h6>
                    <li class="mb-2 mb-md-3"><a class="text-reset" href="https://soyfocus.com/legales/">Documentos legales</a></li>
                    <li class="mb-2 mb-md-3"><a class="text-reset" href="https://soyfocus.com/politicas/">Política de privacidad</a></li>
                    <li class="mb-2 mb-md-3">
                        <a class="text-reset"
                            href="http://www.cmfchile.cl/institucional/mercados/entidad.php?auth=&send=&mercado=V&rut=77057272&grupo=&tipoentidad=RGAGF&vig=VI&row=AAAwy2ACTAAAAQhABS&control=svs&pestania=1"
                            target="_blank">CMF</a>
                    </li>
                </ul>
            </div>
            <div class="col-12 col-md-3">
                <ul class="list-unstyled text-small">
                    <h6 class="text-black mb-4">Contacto</h6>
                    <li class="mb-2 mb-md-3">
                        <a
                            class="text-reset copiloto-help"
                            href="javascript: void(0);"
                            (click)="openChat()"
                        >
                            Hablemos
                        </a>
                    </li>
                    <li class="mb-2 mb-md-3">
                        <a class="text-reset" href="https://ayuda.soyfocus.com/hc/es-419" target="_blank">
                            Centro de ayuda
                        </a>
                    </li>
                    <li class="mb-2 mb-md-3"><a class="text-reset" href="https://bit.ly/soyfocusdireccion"  target="_blank">General Calderón 121, Providencia</a></li>
                    <li class="mb-2 mb-md-3"><a class="text-reset"
                            href="mailto:ayuda@soyfocus.com">ayuda@soyfocus.com</a></li>
                </ul>
            </div>

            <div class="col-md-9 col-lg-9 col-sm-12 px-lg-0 mb-5 mb-md-0 text-center text-md-left mt-4 newsletter">
                <div class="col-first mr-4">
                    <i class="svg-icon-newsletter"></i>
                </div>
                <div class="col-second">
                    <h6 class="text-black">Recibe nuestros Newsletter</h6>
                    <p class="text-reset">Recibe tips de inversión periódicamente.</p>
                </div>

            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 px-lg-0 mb-5 mb-md-0 text-right text-md-right mt-4">
                <div class="input-group input-group-rounded-pill pull-right">
                    <input type="email" class="form-control" placeholder="Escribe tu Email">
                    <div class="input-group-prepend">
                        <button class="btn btn-submit-focus" type="button" id="button-addon1">
                            <i class="svg-icon"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-md-2 col-lg-4 col-sm-12 h-100 align-self-end">
                <!-- <img src="https://cdn.soyfocus.com/static/_front/img/logo/logo-focus.png" alt="Focus"
                    class="img-fluid d-flex mx-auto mx-md-0 ml-md-auto focus-logo"> -->
            </div>

            <div class="w-100 my-3 border-top border-secondary"></div>

            <div class="col-md-4 col-sm-12 mb-5 mb-md-0 text-center text-md-left">
              Regulados por la
              <a style="color: #6161ff; text-decoration: underline;"
                  href="https://www.cmfchile.cl/" target="_blank" rel="noopener noreferrer">
                  Comisión para el Mercado Financiero.
              </a>
            </div>

            <div class="col-md-4 col-sm-12 mb-5 mb-md-0 text-center text-md-center">
                <p class="text-reset copyright">© {{ year }} SoyFocus · Todos los derechos reservados </p>
            </div>

            <div class="col-md-4 col-sm-12 mb-5 mb-md-0 text-right text-md-right redes-sociales">
                <a href="https://www.facebook.com/soyfocus.cl/" target="_blank">
                    <i class="fab fa-facebook-square"></i>
                </a>
                <a href="https://www.instagram.com/soyfocus.cl/" target="_blank">
                    <i class="fab fa-instagram-square"></i>
                </a>
                <a href="https://www.linkedin.com/company/soyfocus/mycompany/" target="_blank">
                    <i class="fab fa-linkedin"></i>
                </a>
                <a href="https://www.youtube.com/channel/UC5bpCCRjH8VUs-dX7D-F-sA" target="_blank">
                    <i class="fab fa-youtube-square"></i>
                </a>
            </div>

            <div class="w-100 my-3 border-top border-secondary"></div>

            <p>
                Los diferentes fondos mutuos son administrados por SoyFocus Administradora General de Fondos S.A.
                La rentabilidad o ganancia obtenida en el pasado por estos fondos, no garantiza que ella se repita en el futuro.
                Los valores de las cuotas de los Fondos Mutuos son variables.
                La rentabilidad es fluctuante, por lo que nada garantiza que las rentabilizadas pasadas se mantengan en el futuro.
                La diferencia en rentabilidad entre alternativas de ahorro previsional voluntario no necesariamente refleja la diferencia en el riesgo de las inversiones.
                Infórmese de las caracterí­sticas esenciales de la inversión en estos Fondos Mutuos, las que se encuentran contenidas en sus reglamentos internos.
            </p>
        </div>
    </div>
</footer>
