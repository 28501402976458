import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform, inject } from '@angular/core';

@Pipe({
  name: 'fondo'
})
export class FondoPipe implements PipeTransform {

  private decimalPipe = inject(DecimalPipe);
  transform(label): string {
    label = label ? this.decimalPipe.transform(label,'1.2-2', 'es-Ar') : '';
    return label ? `${label} %` : 'N.A.';
  }

}
