<header>
	<a [href]="landingUrl" target="_blank">
    <img
      src="https://cdn.soyfocus.com/static/V2/common/logosoyfocus-94x19.png"
      alt="Focus logo"
      width="100%"
      height="100%"
    />
  </a>
</header>
<div class="container" *ngIf="!loading; else loadingTemplate">
  <div class="title-content">
    <div class="title">
      <h1>Nuestros Fondos</h1>
      <p>
        Valores cuota actualizados al
        {{ rentability.date | date: "dd/MM/yyyy" }}
      </p>
    </div>
    <a
      href="https://cdn.soyfocus.com/static/docs/excel/Cuota_Valor_Historico.xlsx"
      download
    >
      <i class="svg-icon"></i>
      Descargar datos
    </a>
  </div>

  <app-fondos
    [fondos]="rentability.fondos"
    [date]="rentability.date"
  ></app-fondos>
  <div class="cta-content">
    <a class="cta" [href]="onboardingUrl" target="_blank">
      Comenzar mi simulación
      <i class="svg-icon"></i>
    </a>
  </div>
</div>
<ng-template #loadingTemplate>
  <section class="loading-content">
    <img
      src="https://cdn.soyfocus.com/static/V2/common/focus_spinner.gif"
      alt="spinner"
      width="65"
    />
  </section>
</ng-template>
