import { Component, OnInit, Input } from '@angular/core';
import { Fondo } from '../../types';

@Component({
  selector: 'app-fondos',
  templateUrl: './fondos.component.html',
  styleUrls: ['./fondos.component.scss']
})
export class FondosComponent implements OnInit {

  @Input() fondos: Fondo[];
  @Input() date: Date;

  constructor() { }

  ngOnInit(): void {
  }

}
