import { Component, OnInit, } from '@angular/core';
import {IntercomService} from '../../services/intercom.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  year: number;

  constructor(private intercomService: IntercomService) { }

  ngOnInit(): void {
    this.year = new Date().getFullYear();
  }

  openChat(): void {
    this.intercomService.showBubble();
  }
}
