import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Rentability } from '../../types';
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(
    private http: HttpClient
  ) { }

  getRentability(): Observable<Rentability> {
    const url = `${environment.apiUrl}/fondosrentabilidad`;
    return this.http.get<Rentability>(url).pipe(
      map((response: Rentability) => {
        let fondoConservador = response.fondos.find(x => x.fondo == 2)
        fondoConservador.variacionUltimos3Ano = 6.612

        let fondoModerado = response.fondos.find(x => x.fondo == 4)
        fondoModerado.variacionUltimos3Ano = 19.223

        let fondoArriesgado = response.fondos.find(x => x.fondo == 5)
        fondoArriesgado.variacionUltimos3Ano = 24.893

        response.fondos = response.fondos.filter(f => !f.titulo.toLowerCase().includes('vivienda') || f.titulo.toLowerCase().includes('vivienda') && f.fondo === 2);
        return response;
      }),

    );
  }
}
