import { Component, OnInit, Input } from '@angular/core';
import { Fondo, Graph } from '../../types';
import { FondoService } from './fondo.service';
import { fadeInUpOnEnterAnimation } from 'angular-animations';
import subDays from 'date-fns/subDays';
import { format } from 'date-fns';

@Component({
  selector: 'app-fondo',
  templateUrl: './fondo.component.html',
  styleUrls: ['./fondo.component.scss'],
  animations: [
    fadeInUpOnEnterAnimation({ anchor: 'enter', duration: 1000, delay: 100, translate: '30px' })
  ]
})

export class FondoComponent implements OnInit {
  showDetail = false;
  loadingDetail = false;
  textButton = 'Ver Gráfico';

  @Input() fondo: Fondo;
  @Input() date: Date;
  ninetyDaysText: string;
  thirtyDaysText: string;

  constructor(
    private fondoService: FondoService
  ) { }

  ngOnInit(): void {
    const quoteValueDate = format(new Date(this.date), 'dd/MM/yyyy');
    const quoteValueLessNinetyDaysDate = format(subDays(new Date(this.date), 90), 'dd/MM/yyyy');
    const quoteValueLessThirtyDaysDate = format(subDays(new Date(this.date), 30), 'dd/MM/yyyy');
    this.ninetyDaysText = `Variación del valor cuota entre el ${quoteValueLessNinetyDaysDate} y el ${quoteValueDate}.`;
    this.thirtyDaysText = `Variación del valor cuota entre el ${quoteValueLessThirtyDaysDate} y el ${quoteValueDate}.`;
  }

  getDetail(): void {

    if (this.showDetail) {
      this.showDetail = false;
      this.textButton = 'Ver Gráfico';
      return;
    }

    if (this.fondo.graph) {
      this.showDetail = true;
      this.textButton = 'Ocultar';
      return;
    }

    this.loadingDetail = true;
    this.fondoService.getDataChart(this.fondo)
      .subscribe((graph: Graph[]) => {
        this.fondo.graph = graph;
        this.textButton = 'Ocultar';
        this.loadingDetail = false;
        this.showDetail = true;
      });
  }


}
